import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Receiver Reports</h3>

          <p>
            The facilities in Columbus, Rome, Savannah, and Montgomery were the
            subject of state court receiverships. Those state court receivership
            orders provided for the filing of monthly operating reports. Those
            operating reports are below. The District of New Jersey Receivership
            Order provides for the filing of Quarterly Status Reports within
            thirty (30) days after the end of each calendar quarter. The
            Quarterly Status Report will include all facilities subject to the
            Receivership Order. The Quarterly Status Reports will be filed
            within thirty days of the end of each calendar quarter.
          </p>

          <p>
            <strong>Columbus:</strong>
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/2017-0322-HMP-Columbus-SU16CV279208-filed-MOR-dated-March-17-2017-4839-3217-9782-v1.pdf"
            >
              3-17-17 Columbus - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/Columbus-Monthly-Operating-Report-2.20.17.pdf"
            >
              2-20-17 Columbus - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Columbus-Monthly-Operating-Report-1.20.17.pdf"
            >
              1-20-17 Columbus - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Columbus-Monthly-Operating-Report-12.20.16.pdf"
            >
              12-20-16 Columbus - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Columbus-Monthly-Operating-Report-11.23.16.pdf"
            >
              11-23-16 Columbus - Monthly Operating Report
              <br />
            </Link>
          </p>
          <p>
            <strong>Rome:</strong>
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/2017-0320-HMP-Rome-SU16CV01749JFL002-filed-MOR-dated-March-17-2017-4850-7630-1125-v1.pdf"
            >
              3-17-17 Rome - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Rome-Monthly-Operating-Report-2.20.17.pdf"
            >
              2-20-17 Rome - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Rome-Monthly-Operating-Report-1.20.17.pdf"
            >
              1-2-17 Rome - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Rome-Monthly-Operating-Report-12.20.16.pdf"
            >
              12-20-16 Rome - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Rome-Monthly-Operating-Report-11.21.16.pdf"
            >
              11-21-16 Rome - Monthly Operating Report
              <br />
            </Link>
          </p>
          <p>
            <strong>Savannah:</strong>
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/2017-0321-HMP-Savannah-CV16-0862-KA-filed-MOR-dated-March-17-2017-4851-9131-9365-v1.pdf"
            >
              3-17-17 Savannah - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/Savannah-Monthly-Operating-Report-2.21.17.pdf"
            >
              2-21-17 Savannah - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Savannah-Monthly-Operating-Report-1.20.17.pdf"
            >
              1-20-17 Savannah - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Savannah-Monthly-Operating-Report-12.20.16.pdf"
            >
              12-20-16 Savannah - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Savannah-Monthly-Operating-Report-11.23.16.pdf"
            >
              11-23-16 Savannah - Monthly Operating Report
              <br />
            </Link>
          </p>
          <p>
            <strong>Waterford:</strong>
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/2017-0317-HMP-Montgomery-CV-2016-901323-Doc.-No.-85-Monthly-Operating-Report-MOR-dated-March-17-2017-4811-6049-2869-v1.pdf"
            >
              3-17-17 Waterford - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Waterford-Monthly-Operating-Report-2.17.17.pdf"
            >
              2-17-17 Waterford - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Waterford-Monthly-Operating-Report-1.19.17.pdf"
            >
              1-19-17 Waterford - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Waterford-Monthly-Operating-Report-12.20.16.pdf"
            >
              12-20-16 Waterford - Monthly Operating Report
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/03/Waterford-Monthly-Operating-Report-11.20.16.pdf"
            >
              11-20-16 Waterford - Monthly Operating Report
              <br />
            </Link>
          </p>
          <p>
            <strong>Quarterly Status Reports:</strong>
          </p>

          <p>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/08/Dkt-No.-276-STATUS-REPORT-Sixth-Quarterly-Operating-Report-for-April-1-2018-through-June-30-1.pdf"
            >
              7/31/18
              <br />
              Docket No. 276 - Receiver's Sixth Quarterly Operating Report for
              the period of April 1, 2018
              <br />
              through June 30, 2018
            </Link>
          </p>

          <p>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/05/Dkt.-264.-Receivers-Quarterly-Report-for-January-1-2018-through-March-31-2018-4823-2573-4756-v.1.pdf"
            >
              5/1/18
              <br /> Docket No. 264 HMP SEC Receivership 17-cv-393 STATUS REPORT
              Receiver's Fourth
              <br />
              Quarterly Operating Report
            </Link>
          </p>

          <p>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/11/147-Receivers-Third-Quarterly-Operating-Report.pdf"
            >
              11/1/17 <br />
              Docket No.147 HMP SEC Receivership 17-cv-393 STATUS REPORT
              Receiver's Third
              <br />
              Quarterly Operating Report
            </Link>
          </p>

          <p>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/08/Second-Quarterly-Operating-Report-1.pdf"
            >
              8/1/17
              <br />
              Docket No. 128 - HMP SEC Receivership 2 17-cv-393 STATUS REPORT
              Receiver's Second 
              <br />
              Quarterly Operating Report
            </Link>
          </p>

          <p>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/05/Docket-No.-109-HMP-SEC-REceivership-1-17-cv-393-STATUS-REPORT-Receivers-First-Quarterly-Opera.pdf"
            >
              5/1/17 <br />
              Docket No. 109 - HMP SEC Receivership 1 17-cv-393 STATUS REPORT
              Receiver's First
              <br />
              Quarterly Operating Report
            </Link>
          </p>
        </div>
      </Layout>
    )
  }
}
